@use '../../../variables' as *;

.breadcrumb-container {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: flex-start;
    gap: $spacing-xs;
    padding: $spacing-xs $spacing-md;
    border-radius: $radius-sm;
    background: $color-purple-20;

    .preheader {
        text-overflow: ellipsis;
    }

    .breadcrumbs {
        flex: 1;
    }
}

@media (max-width: $vw-tablet) {
    .touchscreen {
        .breadcrumb-container {
            height: 48px;
        }
    }
}

@media (max-width: $vw-mobile) {
    .breadcrumb-container {
        height: 48px;
    }
}