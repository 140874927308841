@use '../variables' as *;

#learning.page-wrapper #content-wrapper #content {
  flex-direction: column;

  .page-content {
    align-items: center;
    height: 100%;
    
    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: $spacing-xs;
      height: auto;
      border: none;
      border-radius: 0;
      background: none;
      height: 100%;
    }
  }
}

[id^="learning-"] .main:not(.pdf-viewer) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-lg, 32px);
  background: var(--white, #FFF);   
  border-radius: var(--radius-md, 16px);
  border: 2px solid var(--purple-40, #9A93FB);
  overflow: auto;
  width: 100%;

  .programming-wrapper {
      padding: 0;
      min-height: 480px;
  }
}

.quiz h3 {
  margin-top: 24px;
}

.activity-content .programming-wrapper {
  padding: 0;
  border: 2px solid var(--purple-20);
  border-radius: 16px;
  overflow: hidden;
  gap: 0;
}

.activity-content .programming-wrapper .action-bar {
  padding: 16px;
  border-bottom: 2px solid var(--purple-20);
}

.activity-content .question {
  margin-bottom: 48px;
  gap: 16px;
}

.activity-content .question:last-of-type {
  margin-bottom: 0;
}

.question-container{
  display: flex;
  padding: var(--spacing_sm, 16px) 0 var(--spacing_xs, 8px) 0;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.question-title {
  margin-bottom: 16px;
}

.question-item{
  display: flex;
  align-items: center;
  gap: var(--spacing_xs, 8px);
  margin-bottom: 8px;
}

.question-value{
  display: flex;
  gap: var(--spacing_xs, 8px);
  cursor: pointer;
}

.question-checkbox {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--purple-40);
  border-radius: 1000px;
  background: var(--white);
}

.question-checkbox.correct {
  border: 2px solid var(--green-40);
  background: var(--green-40);
}

.question-checkbox.incorrect {
  border: 2px solid var(--red-40);
  background: var(--red-40);
}

.question-checkbox svg {
  width: 16px;
  fill: var(--white)
}

.login-inputs {
  align-self: center;
}

.lesson-frame {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: $vw-mobile) {
  #learning-lesson.page-wrapper {
    #navbar-mobile {
      display: none;
    }
  
    #content-wrapper {
      padding: 0;
  
      .page-title {
        display: none;
      }
  
      .page-content {
        height: 100%;
        padding: 0;
  
        .action-bar {
          display: none;
        }
  
        .pdf-viewer {
          border: none;
          border-radius: 0;
        }
      }
    }
  }
}