@use '../../variables' as *;


.login-form-bottom{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs, 8px);
    align-self: stretch;
}

.login-inputs {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs, 8px);
    align-self: stretch;

    .login-input {
        font-family: 'Space Grotesk', Arial, Helvetica, sans-serif;
        font-size: 32px;
        line-height: 100%;
        border-radius: var(--radius-sm, 8px);
        border: $stroke-width-default $stroke-type-default var(--purple-20, #E5E5FF);
        background: var(--white, #FFF);
        display: flex;
        width: 48px;
        height: 64px;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-xs, 8px);
        text-align: center;
    }

    input:focus-visible {
        outline: $color-purple-40 auto $stroke-width-default;
    }
}
